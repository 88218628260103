
export const Routes = {
    // pages
    Dashboard: { path: "/" },
    TakeOrder: { path: "/takeorder/:tableName" },
    LogTable: { path: "/logs" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Menu: { path: "/menu1" },
    Menu2: { path: "/menu2" },
    Menu3: { path: "/menu3" },
    Barista: { path: "/barista" },
    MenuSettings: { path: "/menusettings" },
    Products: { path: "/menu1/products/:categoryName" },
    Products2: { path: "/menu2/products/:categoryName" },
    Products3: { path: "/menu3/products/:categoryName" },
    BootstrapTables: { path: "/tables/bootstrap-tables" },
    Billing: { path: "/examples/billing" },
    Invoice: { path: "/examples/invoice" },
    Signin: { path: "/signin" },
    Signup: { path: "/examples/sign-up" },
    ForgotPassword: { path: "/examples/forgot-password" },
    ResetPassword: { path: "/examples/reset-password" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/examples/404" },
    ServerError: { path: "/examples/500" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }
};